.PaymentForm {
    width: 100%;
    height: 100%
}

.FormGroup {
    display: flex;
    flex-direction: column;
    font-family: Montserrat, sans-serif;
}

.FormRow {
    margin: 5px 10px 10px 5px;
    display: flex;
    flex-direction: row;
}

.FormRow3 {
    margin: 5px 5px 5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input-errors {
    font-size: 0.95em;
    color: crimson;
    margin-left: 10px;
}

.FormRow2 {
    margin: 5px 5px 5px 5px;
    display: flex;
    flex-direction: row;
}
.FormRow3 {
    margin: 10px 5px
}

@media only screen and (max-width: 500px) {
    .FormRow {
        display: flex;
        flex-direction: column;
        margin: 5px 10px 0 5px;
    }

    .FormRow2 {
        display: flex;
        flex-direction: column;
    }

    .FieldThirdWidth {
        margin: 5px 10px 10px 5px;
    }

    .FieldThirdWidth2 {
        margin: 5px 15px 10px 5px;
    }

    .FieldCardNumber {
        margin: 5px 10px 10px 5px;
    }
}


.FieldCardNumber {
    height: 50px;


}

.FieldThirdWidth {
    height: 50px;
    margin-right: 15px;


}

.FieldThirdWidth2 {
    height: 50px;
}

.Error {
    outline: 1px solid red;
    outline-offset: -3px;
    border-radius: 4px;
    border: 2px solid red;
}

/*prevent display of the footer that clover auto-injects at the bottom of the screen*/
.clover-footer {
    display: none;
}


.helper-text {
    color: #e93143;
    font-size: 12px;
    margin-bottom: 5px;
    margin-left: 5px;
    height: 12px;
    display: block;
}

.helper-text-exp {
    color: #e93143;
    font-size: 12px;
    margin-bottom: 5px;
    margin-left: 5px;
    height: 12px;
    display: block;
}

.helper-text-cvv {
    margin-left: 5px;
    color: #e93143;
    font-size: 12px;
    margin-bottom: 5px;
    height: 12px;
    display: block;

}

@media only screen and (max-width: 500px) {
    .helper-text-cvv {
        margin-top: 0;
    }

    .helper-text-exp {
        margin-top: 0;
    }

    .helper-text {
        margin-top: 0;
    }
}

/* Hosted Payment Fields styles*/
.FormRowBlueSnap {
    margin: 5px 10px 10px 5px;
    display: flex;
    flex-direction: row;
}

.form-control {
    height: 55px !important;
    border: 1px solid #aaa !important;
    border-radius: 4px !important;
    margin-bottom: 5px !important;
}

.form-control-focus {
    display: block;
    height: 55px;
    border: 1px solid #913AFF;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
}

.form-control-invalid {
    display: block;
    height: 55px;
    border: 1px solid #e93143;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
}

.spacing {
    margin-left: 8px;
}


@media only screen and (max-width: 500px) {
    .spacing {
        margin-left: 0;
    }

    .FormRow {
        margin: 5px 5px 0px 5px;
        display: flex;
        flex-direction: row;
    }
}


