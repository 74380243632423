.dropdown-menu {
    right: 0 !important;
    left: auto !important;
}

.dropdown-item {
    font-weight: bold !important;
    color: black !important;
    margin: 1px;
    transition: background-color 250ms ease;
}

.dropdown-item:hover {
    color: #9139ff !important;
    background: transparent;
}

.dropdown-item:active {
    background-color: #f4f4f4 !important;
}

.dropdown-item.active {
    background-color: #f4f4f4 !important;
}

.dropdown-toggle.focus {
    background-color: #f4f4f4 !important;
}


.sizeMenu {
    max-width: 1200px;
    height: 100px;
    background: white;
}

header {
    border-bottom: 1px solid #333333;
}
.menuIcon {
    background: black;
}

.nav-link {
    font-family: "Messina Sans", sans-serif;
    font-weight: 700;
    line-height: 38px;
    color: #2e2343;
    background-image: url("../../Images/CircleBackgound.svg");
    background-repeat: no-repeat;
    background-position: center;

}


.circleWithInitials {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 0;
    background-color: #fedf4a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.navbar {
    font-size: 1rem;
    
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;
    margin-right: 20px;
}

.navbar-light .navbar-nav .nav-link {
    font-weight: bold;
    color: black !important;

}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #9139ff !important;
    background: transparent;
}

a:not([href]):not([tabindex]) {
    color: #4d3b71;
    text-decoration: none;

}

a:not([href]):not([tabindex]):hover {
    color: #9139FF;
    text-decoration: underline;

}

