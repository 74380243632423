

.mui-override .MuiStepConnector-lineVertical {
    min-height: 8px !important;
    /*max-height: 2px !important;*/
}
.mui-override .MuiStepConnector-vertical {
    padding: 0 0 3px !important;
}
.mui-override .MuiStepLabel-iconContainer {
    padding-right: 13px !important;
}

.mui-override .MuiFormControl-root {
    display: flex;
}
.mui-override .MuiStepper-root {
    padding-bottom: 10px;
    /*padding-top: 0;*/
}
.mui-override-accordion .MuiPaper-elevation1 {
    box-shadow: none;
}