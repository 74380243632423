body {
    height:'100%';
    width: '100%';
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
}
html {
    height: 100%;
    width: 100%;
  }
  
  div#root {
    height: 100%; /* remove this line to see div.app is no more 100% height */
    width: 100%;
    background-color: #fff;
  }
  
  div.app {
    height: 100%;
    width: 100%;
    background-color: cornsilk;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }


