/*Loading spinner start*/

.spinner {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dashed {
    stroke-dasharray: 100;
    stroke: white;
}
.dashed2 {
    stroke: #9139ff;
    stroke-dasharray: 70;
    stroke-dashoffset: 38;
    animation: Dash 1s ease-in-out forwards infinite;
}
@keyframes Dash {
    50% {
        stroke-dashoffset: -28;
    }

    100% {
        stroke-dashoffset: -238;
    }
}
/*Loading spinner end*/


